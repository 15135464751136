<script setup lang="ts">
import type { ComputedRef } from "vue";
import type { Notification } from "@shopware/composables";

defineEmits<{
  (e: "click:close", id: number): void;
}>();

const props = defineProps<{
  notification: Notification;
}>();

const colorCssMap = {
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
};

// i-carbon icons map
const iconsMap = {
  info: "information",
  success: "checkmark",
  warning: "warning-alt",
  danger: "close-outline",
};

const themeTypeColor: ComputedRef<string> = computed(
    () => colorCssMap[props.notification.type] || "blue",
);
const icon = computed(() => iconsMap[props.notification.type] || "information");
</script>
<template>
  <!-- don't remove; enforce unocss to include dynamically used classes: class="bg-blue-100 bg-green-100 bg-orange-100 bg-red-100" -->
  <div
    v-if="notification.message.length > 0"
    :id="`toast-${notification.id}`"
    :data-testid="`notification-element-${notification.type}`" class="notifications shadow" :class="`alert alert-${themeTypeColor}`" role="alert">
    <div data-testid="notification-element-message" class="ml-3 text-md font-normal" v-html="notification.message">

    </div>
    <button
      data-testid="notification-element-button"
      type="button"
      class="border-0 btn-close notifications-close"
      :data-dismiss-target="`toast-${notification.id}`"
      aria-label="Close"
      @click="$emit('click:close', notification.id)"
    >
      X
    </button>
  </div>
</template>
